import React, { useState, useEffect } from 'react';
import image1 from '../assets/image/pexels1.png';
import image2 from '../assets/image/pexels2.png';
import image3 from '../assets/image/pexels3.png';

const defaultImages = [
  { image: image3, title: 'Where Tradition Meets Intricate', text: 'Artistry in Every Stitch' },
  { image: image2, title: 'Where Tradition Meets', text: 'Modern Elegance' },
  { image: image1, title: 'Handcrafted Aari Embroidery', text: 'For Your Wardrobe' },
];

function Carousel({ images = defaultImages }) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent(prev => (prev + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const handleDotClick = (index) => {
    setCurrent(index);
  };

  return (
    <div id="home">
      <section>
        <div className="relative overflow-hidden">
          <div
            className="flex transition-transform ease-out duration-1000"
            style={{ transform: `translateX(-${current * 100}%)` }}
          >
            {images.map((slide, i) => (
              <div className="w-full flex flex-shrink-0 justify-start items-start" key={i}>
                <div
                  className="w-full lg:h-screen md:h-96 h-96 flex justify-start pb-40 items-center bg-cover bg-center"
                  style={{ backgroundImage: `url(${slide.image})` }}
                >
                  <div className="text-center p-4">
                    <div className="bg-gray-500 bg-opacity-20 rounded-lg p-4">
                      <p className="text-lg lg:text-4xl font-poppins text-white text-opacity-50 font-bold text-start">
                        <span>{slide.title}</span>
                      </p>
                      <p className="text-lg lg:text-4xl font-poppins text-white text-opacity-50 font-bold pt-5 text-start">
                        <span>{slide.text}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Dots for slide navigation */}
          <div className="absolute md:bottom-[50px] lg:bottom-[90px] left-1/2 transform -translate-x-1/2 flex gap-[10px]">
            {images.map((_, index) => (
              <div
                key={index}
                className={`w-[15px] h-[15px] rounded-full cursor-pointer ${
                  index === current ? 'bg-darkpink' : 'bg-yellow'
                }`}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Carousel;
