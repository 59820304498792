import React from 'react';
import { useNavigate } from 'react-router-dom';
import bunch from '../../assets/image/3MM-5-Bunch-Beads-Each-12-Lines-String-Gold-or-Dull-Gold-Color-2.png';
import hozyjql from '../../assets/image/61+hHozyJqL.png';
import img51 from '../../assets/image/20200925023552_IMG_5180 (1).png';
import img52 from '../../assets/image/20200925023552_IMG_5180.png';
import ellipse1 from '../../assets/image/Ellipse 72.png';
import glass from '../../assets/image/Glass-Crystal-Stone-With-Flower-Catcher-On-Sew-green-1.png';
import ring from '../../assets/svg/Vector.svg'; 
import flower from '../../assets/svg/Mask1.svg';
import Pricing from '../Pricing/Pricing';

function Product() {
  const navigate = useNavigate();

  const products = [
    { id: 1, name: 'Beads', image: bunch },
    { id: 2, name: 'Aari Work Stand', image: hozyjql },
    { id: 3, name: 'Fabric', image: img52 },
    { id: 4, name: 'Needles', image: img51 },
    { id: 7, name: 'Bunch Beads', image: ellipse1 },
    { id: 8, name: 'Stones', image: glass },
  ];

  const handleProductClick = (id) => {
    if (id) {
      navigate(`/Product/${id}`);
    }
  };

  return (
    <div id="products" className="relative bg-orange-50 py-20 bg-no-repeat">
      <img src={flower} alt="background" className="absolute -top-56 object-cover z-0" />
      <h2 className="text-3xl text-center font-bold pb-16 text-darkpink mb-10 relative z-10">Our Products</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-12 z-10 justify-items-center">
        {products.slice(0, 3).map((product, index) => (
          <div 
            key={index} 
            className="text-center relative cursor-pointer" 
            onClick={() => handleProductClick(product.id)}
          >
            <div className="relative w-32 h-32 lg:w-36 lg:h-36 rounded-full mx-auto mb-4">
              <div className="pl-2 lg:pl-2 pt-3 lg:pt-4">
                <img src={product.image} alt={product.name} className="w-28 lg:w-32 h-28 lg:h-32 object-cover z-10" />
              </div>
              <img src={ring} alt="ring" className="absolute inset-0 w-full h-full z-20 pointer-events-none" />
            </div>
            <h3 className="text-lg text-darkpink pb-4 font-semibold">{product.name}</h3>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-12 z-10 justify-items-center mt-10">
        {products.slice(3, 6).map((product, index) => (
          <div 
            key={index} 
            className="text-center relative cursor-pointer" 
            onClick={() => handleProductClick(product.id)}
          >
            <div className="relative w-32 h-32 lg:w-36 lg:h-36 rounded-full mx-auto mb-4">
              <div className="pl-2 lg:pl-2 pt-3 lg:pt-4">
                <img src={product.image} alt={product.name} className="w-28 lg:w-32 h-28 lg:h-32 object-cover z-10" />
              </div>
              <img src={ring} alt="ring" className="absolute inset-0 w-full h-full z-20 pointer-events-none" />
            </div>
            <h3 className="text-lg text-darkpink pb-4 font-semibold">{product.name}</h3>
          </div>
        ))}
      </div>
      <div className="z-10">
        <Pricing />
      </div>
    </div>
  );
}

export default Product;
