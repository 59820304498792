import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import flower from '../../assets/svg/Group.svg';
import bead1 from '../../assets/image/81ZWUy0X9QL._AC_UF894,1000_QL80_.jpg';
import bead2 from '../../assets/image/beads/beads1.jpg';
import bead3 from '../../assets/image/beads/beads2.jpg'
import bead4 from "../../assets/image/beads/beads3.jpg"
import aari1 from '../../assets/image/6_1200x1200.webp';
import aari2 from '../../assets/image/maxresdefault.jpg';
import fab1 from '../../assets/image/fabric/fab.jpg';
import fab2 from '../../assets/image/fabric/fab2.jpg';
import fab3 from '../../assets/image/fabric/fab3.jpg';
import fab4 from '../../assets/image/fabric/fab4.jpg';
import needle1 from '../../assets/image/needle121.jpg';
import needle2 from '../../assets/image/product-jpeg.jpg';
import need1 from "../../assets/image/needel/need1.webp"
import need2 from "../../assets/image/needel/need4.webp"
import bounch1 from "../../assets/image/bounch/bon1.jpg"
import bounch2 from "../../assets/image/bounch/bon2.jpg"
import image1 from '../../assets/image/images1.jpeg';
import image2 from '../../assets/image/a0nwq_512.jpg';
import stone1 from '../../assets/image/stone1.jpg';
import stone2 from '../../assets/image/stone2.jpg';
import stand from "../../assets/image/stand.webp"
import stand2 from "../../assets/image/stand2.webp"
import sto1 from "../../assets/image/stone/sto1.jpg"
import sto2 from "../../assets/image/stone/sto2.jpg"

const ProductData = {
  1: {
    title: 'Beads',
    description: 'Bead, small usually round object made of glass, wood, metal, nut shell, bone, seed, or the like pierced for stringing.',
    images: [bead1, bead2, bead3, bead4],
  },
  2: {
    title: 'Aari Work Stand',
    description: 'Detailed description about the Aari Work Stand.',
    images: [aari1, aari2, stand, stand2],
  },
  3: {
    title: 'Fabric',
    description: 'Detailed description about Fabric.',
    images: [fab1, fab2, fab3, fab4],
  },
  4: {
    title: 'Needles',
    description: 'Detailed description about Needles.',
    images: [needle1, needle2, need1, need2],
  },
  7: {
    title: 'Bunch Beads',
    description: 'Detailed description about Bunch Beads.',
    images: [image1, image2, bounch1, bounch2],
  },
  8: {
    title: 'Stones',
    description: 'Detailed description about Stones.',
    images: [stone1, stone2, sto1, sto2],
  },
};

function ProductPage() {
  const { id } = useParams();
  const product = ProductData[id] || {};

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, [id]); // Re-run when the id changes

  if (!product.title) {
    return (
      <div className="flex justify-center items-center h-screen w-full bg-white">
        <p className="text-lg text-red-500">Product not found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center h-auto w-full overflow-hidden bg-orange-50 p-6 relative">
      {/* Decorative Flower */}
      <div className="absolute -left-32 lg:-left-72 -top-28 lg:top-0">
        <img className="w-64 lg:w-[700px]" src={flower} alt="Decorative Flower" />
      </div>

      {/* Product Details */}
      <div className="z-10 pl-6 lg:pl-20 max-w-xl text-center lg:text-left pt-20 pb-10 lg:w-1/2">
        <p className="font-poppins text-3xl lg:text-4xl font-bold text-darkpink">{product.title}</p>
        <p className="mt-4 lg:mt-6 font-poppins text-base lg:text-lg text-darkpink leading-relaxed">{product.description}</p>
      </div>

      {/* Product Images */}
      {product.images && (
        <div className="z-10 flex flex-col items-center lg:items-end pr-6 lg:pr-40 lg:w-1/2 gap-6 mt-10 lg:mt-0">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6 lg:gap-8">
            {product.images.map((image, index) => (
              <div
                key={index}
                className="relative group w-full h-60 lg:h-64 p-4 border-2 md:border-4 border-darkpink rounded-lg shadow-md bg-white overflow-hidden transition-transform duration-300 hover:scale-110"
              >
                <img
                  src={image}
                  alt={`${product.title} image ${index + 1}`}
                  className="w-full h-full object-cover  transition-transform duration-300"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
