import React from 'react';
import flower from '../../assets/svg/Group.svg'; // Background SVG
import girl from '../../assets/image/Rectangle 29731.png'; // Main image
import girl1 from '../../assets/image/Rectangle 29732.png'; // Second image
import girl2 from '../../assets/image/Rectangle 29733.png'; // Third image

function AboutUs() {
  return (
    <div id='about' className="relative flex flex-col lg:flex-row justify-between items-center md:py-28 py-10 px-4 lg:px-8 bg-white overflow-hidden">
      <div className='flex flex-col md:flex-row'>
        <div className="lg:w-1/2 w-full space-y-6 mb-8 lg:mb-0 text-center pl-10 lg:text-left">
          <h2 className="text-3xl lg:text-4xl pr-5 font-bold text-[#8D0044]">About Us</h2>
          <p className="text-darkpink text-pretty pr-7 md:pr-0 text-justify leading-relaxed lg:px-0">
            Dedicated to creating premium quality footwear since its inception in 1995, 
            Sole Enterprises ranks among the best in the leather shoes and goods manufacturing 
            industry. With a passion for craftsmanship and style, we have turned the process 
            of footwear making into an art form. Family-owned and operated for generations, 
            our customer list includes premium international brands and fashion houses. Offering 
            high-quality fashion footwear and accessories unique and best in the fashion world, 
            is our tribute to the art of leather craftsmanship.
          </p>
        </div>
        <div className='md:pt-[350px] pt-5 pl-16 md:pl-0 pb-5 hidden'>
          <button className="px-6 py-2 border rounded-md border-[#F7D235] text-[#8D0044] hover:bg-[#F7D235]">
            LEARN MORE
          </button>
        </div>
      </div>

      <div className="relative lg:w-1/2 w-full flex justify-center lg:justify-end">
        <div className="absolute -top-48 left-1/2 lg:-left-72 transform -translate-x-1/2 lg:transform-none z-10 w-[500px] lg:w-[800px]">
          <img src={flower} alt="Background Flower" className="w-full" />
        </div>

        <div className="space-y-4 w-[280px] lg:w-[350px] pr-4 lg:pr-20 z-20">
          <img src={girl} alt="Main Image" className="rounded-lg shadow-lg w-full" />
          <div className="grid grid-cols-2 gap-4">
            <img src={girl1} alt="Image 1" className="rounded-lg shadow-lg w-full" />
            <img src={girl2} alt="Image 2" className="rounded-lg shadow-lg w-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
