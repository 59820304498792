import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProductPage from './pages/Product/ProductPage';
import ClassPage from './pages/Classes/ClassPage';

const MainPage = () => {
  return (
    <Router>
      <Navbar />
      {/* <Carousel /> */}
      
      {/* <section id='home'>
        <Home />
      </section> */}
     

      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/Product/:id' element={<ProductPage/>} />
        <Route path="/Class/:id" element={<ClassPage/>} /> 
      </Routes>
      
      <Footer />
    </Router>
  );
};

export default MainPage;
