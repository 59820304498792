import React from 'react';
import facebook from '../assets/svg/facebook.svg';
import twitter from '../assets/svg/twitter.svg';
import insta from '../assets/svg/instagram.svg';

function Footer() {
  const openFacebook = () => {
    window.open('https://www.facebook.com', '_blank');
  };

  return (
    <div className="bg-darkpink">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 md:px-20">
        <div className='pt-10'>
          <p className="text-3xl font-bold font-poppins text-yellow">SAV TRADERS</p>

          <div className='flex flex-col text-white font-poppins' style={{ fontSize: "13px" }}>
            <p>K.L.R. Complex, Beach Road,</p>
            <p>Near Muthoot Finance,</p>
            <p>Periyavilai, Nagercoil.</p>
            <p>+91 63693 93765, 97902 89853.</p>
          </div>
        </div>
        <div className="mt-4 md:mt-0 pt-10 flex space-x-4">
          <img
            src={facebook}
            alt="Facebook"
            className="w-8 h-8 cursor-pointer md:w-10 md:h-10 transition ease-in-out transform hover:-translate-y-1 hover:scale-110 duration-300"
            onClick={openFacebook}
          />
          <img
            src={twitter}
            alt="Twitter"
            className="w-8 h-8 cursor-pointer md:w-10 md:h-10 transition ease-in-out transform hover:-translate-y-1 hover:scale-110 duration-300"
            onClick={() => window.open('https://www.twitter.com', '_blank')}
          />
          <img
            src={insta}
            alt="Instagram"
            className="w-8 h-8 cursor-pointer md:w-10 md:h-10 transition ease-in-out transform hover:-translate-y-1 hover:scale-110 duration-300"
            onClick={() => window.open('https://www.instagram.com', '_blank')}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center pb-10">
        {/* Additional content */}
      </div>
      <div className='flex justify-center'>
        <p className='pb-5 font-poppins text-md text-yellow'>2024 copyrights reserved by <a href='https://eloiacs.com/' target='_blank' className='hover:text-orange-300'>ELOIACS</a></p>
      </div>
    </div>
  );
}

export default Footer;
