import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import flower4 from '../../assets/svg/Mask group (1).svg';

const classData = [
  { title: "Aari Class", price: "₹ 6000", id: 2 },
  { title: "Saree Pre Pleating class", price: "₹ 1000", id: 6 },
  { title: "Certificate Programs", price: "₹ 8000", id: 7 },
  { title: "Fabric painting", price: "₹ 8000", id: 8 },
  { title: "Mehndi Class", price: "₹ 1000", id: 9 },
  { title: "Tracing Class", price: "₹ 2000", id: 10 },
  { title: "Prooches Class", price: "₹ 4000", id: 11 },
];

function Class() {

  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();

  const handleClassClick = (id) => {
    navigate(`/Class/${id}`);
  };

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div id='classes' className='bg-gray-50  relative'>
      <img 
        src={flower4} 
        className='w-full h-[900px] opacity-10 absolute object-cover z-0' 
        alt="Background Decoration"
      />
      <div className='relative z-10'>
        <p className='font-poppins font-bold text-darkpink text-3xl pt-10 text-center'>
          Classes we Offer
        </p>
        <div className='px-4 md:px-10 lg:px-20 py-10'>
          <div className='flex flex-wrap justify-center gap-8'>
          {classData.slice(0, showAll ? classData.length : 6).map((classItem) => (
              <div key={classItem.id} className='w-full sm:w-[300px]'>
                <div 
                  onClick={() => handleClassClick(classItem.id)}
                  className="bg-darkpink cursor-pointer w-full h-72 rounded-lg p-4 relative text-yellow-500 font-semibold transition ease-in-out transform hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="text-lg text-yellow font-poppins">{classItem.title}</p>
                      <p className="text-lg font-poppins text-yellow pt-6">{classItem.price}</p>
                    </div>
                    <div className="absolute bottom-0 -right-2 overflow-hidden">
                      <img src={flower4} alt="Decoration" className="h-40 w-36" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='text-center mt-5'>
            <button onClick={handleToggleShowAll} className='font-poppins text-darkpink'>
              {showAll ? 'View Less' : 'View More'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Class;
